import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import DescriptionCard from '@components/DescriptionCard'
import { Grid } from '@components/Grid'
import './MusicalShowcasesPage.css'
import YouTube from 'react-youtube'

function MusicalShowcasesPage() {
  const data = useStaticQuery(graphql`
  query {
    gridImages: allFile(filter: { name: { regex: "/musicalshowcases-grid-*/" }, extension: { regex: "/(jpeg|jpg|gif|png)/" }, sourceInstanceName: { eq: "images"}}) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
  `)

  function getTitle() {
    return "About Musical Showcases"
  }

  function getDescription() {
    return <div>
      <p>Upon opening her own business Musical Showcases in 2008, Sara first started out with an all-string trio consisting of guitar, violin, and bass, entitled “The New Mood Ensemble,” and after a number of gigs, a piano was added to the mix,  leading to a variety of gigs in the greater Boston and Metrowest area. </p>

      <p>Sara writes for her own business “Musical Showcases.”   Her Revue “The Music Box Plays On” about 20th Century Broadway Composers, features Ashley Lieberman, actress and vocalist, who can bring comedy and tragedy to a level of realness, Sheryl Altman, with her anecdotal humor and "in the moment characterizations, and Bill Zolli, with a tonal depth that resounds from his heart and translates into vintage entertainment.  Sara Curry,vocalist/writer in the direction of her Revue, imparts her inviting storytelling into her songs,and by way of this Revue, with its seasoned performers, hopes to portray the enduring unquenchable light of "The Great White Way.  With Bonnie MacLeod on piano, whether it is her piano interpretations for early Ragtime, or conceptually dramatic present-day theatre, her personal appeal rings true.  The show was performed four times here in New England..</p>

      <p>See Professional Comments on "The Music Box Plays On"</p>

      <p>It was my pleasure to come to your show - you put a lot of work into it! Everyone sang some awesome songs and I loved your facts about the songs, composers and shows - I always enjoy that. The cast was great and your grandsons held their own right along with everyone else. Teddy Edgar has a beautiful voice!  Krisanthi Pappas, Cabaret Performer, (Composer, Pianist, Vocalist)</p>

      <p>I thought the show was TREMENDOUS! It was above my expectations. The effort on everyone's part was just awe-inspiring.. I am looking forward to another one. I know it takes tremendous behind the scenes work on everyone's part, but it was just plain worth it!</p>

      <p>Nick Paganella MetroWest Newspapers, Veterans Corner</p>
      <hr />

      <p>You had great tunes and great talent.  You pulled off one amazing show. Your two grandsons did a FABULOUS job! They both have a lot of talent!!! May all your productions take wing and fly!</p>

      <p>Facebook posting: “Great Show…Great Sings Skillfully Shared…Laughs and Tears.” Heartfully,Linda Marks Listen to your body...follow your heart...www.healingheartpower.com­­­­­­­­­­­­­­­­­­</p>
      <hr />

      <p>Congratulations on a superb show. I loved your narration and the reverence and energy you brought to your storytelling about the brilliant musical talent of the 20th Century. Thank you for a great show. Great numbers, very well executed from the narrative you wrote and delivered, to the selection of songs and singers, to the highly talented Bonnie MacLeod on piano, and for the seamless substitutions provided by two very talented young men, since  your lead singer took ill. I look forward to more! Julie A. KatzMove Your NestMove Coaching & Coordination.</p>
      <hr />

      <p> In addition Sara also put together a variety of "themed" shows throughout the years, and also special events for the holidays at senior living venues.  Her other show "Zany Sophisticated Ladies" is hilariously funny with many hat changes done collaboratively with pianist Bonnie MacLeod.  </p>

      <p>It is also her joy to sing regularly with the Holliston Big Band on Friday afternoons, and she just produced her Multi-Cultural Show with three musicians and two trained ballroom dancers to be performed at Orchard Hill Assisted Living in Sudbury in June , and Mendon Senior Center in August with a pianist performing "In The Goodie Old Summertime."  Comedy is a very big part of Sara's productions....people remember the things they laugh about.  </p>

      <p>Sara was happy to work with the Girl Scouts at a Senior Living Event, and encouraged her grandchildren to participate there and in Boston Cabaret's Youth Development Performance Showcase 2015.   All these youngsters study theatre and love singing and dancing.  It was very well received at the senior venue., and we were all invited back..</p>
    </div>
  }

  return (
    <div className="collection-page-container">
      <div className="card">
        <DescriptionCard
          longDescription
          noButton
          title={getTitle()}
          description={getDescription()}
        />
      </div>

      <YouTube videoId="cqQ_3SFmFAI" />
      <div className="image-grid">
        <Grid items={data.gridImages.edges} />
      </div>
    </div>
  )
}

export default MusicalShowcasesPage