import React from "react"
import Layout from "@components/Layout"
import SEO from "@components/SEO"
import MusicalShowcasesContainer from "@components/MusicalShowcasesPage"

function MusicalShowcasesPage() {
  return (
    <Layout>
      <SEO title="Musical Showcases" />
      <MusicalShowcasesContainer />
    </Layout>
  )
}

export default MusicalShowcasesPage
